import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@core/services/app.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { environment } from '@env/environment';
import { TranslocoService } from '@ngneat/transloco';
import { Spinner } from '@shared/models/spinner';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  spinnerObject!: Spinner;
  constructor(
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private translocoService: TranslocoService,
    private appService: AppService
  ) {
    this.spinnerObject = {
      loadingTitle: 'global.loading-spinner-auth-title',
      loadingSubtitle: 'global.loading-spinner-auth-subtitle',
      isLoading: false,
    };
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    // if (err.status !== 200) {
    if ([401, 302, 0].includes(err.status)) {
      // remove 403
      if (err.url?.endsWith('api/userinfo') === true) {
        this.spinnerObject.isLoading = true;
        this.appService?.spinnerSubject?.next(this.spinnerObject);
        setTimeout(() => {
          // reload if url is userinfo
          const host = window.location.hostname;
          this.spinnerObject.isLoading = false;
          this.appService?.spinnerSubject?.next(this.spinnerObject);
          window.location.href =
            host === 'localhost'
              ? 'https://fcsc-dev.vus.corpinter.net/login'
              : 'https://' +
                window.location.host +
                environment.i3Access.loginUrl;
        }, 3000);
      }
      return of(err.message);
    }
    return throwError(() => err);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authReq = req.clone();
    return next
      .handle(authReq)
      .pipe(catchError((error) => this.handleAuthError(error)));      
  }
}
