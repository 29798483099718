import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorCodesFromServer } from '@core/enums/error-codes-server.enum';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(
    private toastr: ToastrService,
    private translateService: TranslocoService
  ) {}

  showError(errorType: string, errorMessage: string) {
    this.clearAll();
    this.toastr.error(errorMessage, errorType, { timeOut: 2000 });
  }

  showSuccess(errorType: string, errorMessage: string) {
    this.clearAll();
    this.toastr.success(errorMessage, errorType, { timeOut: 1500 });
  }

  showWarning(errorType: string, warningMessage: string) {
    this.toastr.warning(warningMessage, errorType);
  }

  clearAll() {
    this.toastr.clear();
  }

  handleServerErrorNotification(
    err: HttpErrorResponse | null,
    clientMessageKey: string,
    paramsExternal?: any
  ) {
    this.clearAll();
    const error = err?.error;
    const code = error?.message_CODE;
    const params = error?.params;
    const statusCode = err?.status;
    const errorMessage = error?.message;
    let result = '';
    if (error && code) {
      const errorMessageKey = ErrorCodesFromServer[code];
      if (errorMessageKey) {
        const message = this.translateService.translate(errorMessageKey);

        if (message) {
          if (params && params.length) {
            result = params.reduce(
              (f, s, i) => `${f}`.replace(`{${i}}`, s),
              message
            );
          } else {
            result = message;
          }
          result = code ? ` ${code} - ${result}` : result;
        }
      }
    }
    if (statusCode && errorMessage)  {
      let errorToDisplay = ` ${statusCode} - ${errorMessage}`
      this.showError('', errorToDisplay);
    } else if (clientMessageKey) {
      let finalParams = {};
      const errorParams = {
        errorCode: result,
      };
      if (paramsExternal) {
        finalParams = {
          ...errorParams,
          ...paramsExternal,
        };
      } else {
        finalParams = errorParams;
      }

      this.showError(
        '',
        this.translateService.translate(clientMessageKey, finalParams)
      );
    }else {
      this.showError('', result);
    }
  }
}
