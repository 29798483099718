import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { StatusBdp } from '@core/enums/status-bdp.enum';
import { AppService } from '@core/services/app.service';
import { CalculationLogFilesService } from '@core/services/calculation-log-file.service';
import { DownloadService } from '@core/services/download.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { FileImportService } from '@core/services/files/import/file-import.service';
import { UserSettingService } from '@core/services/user-setting.service';
import { entitlements } from '@env/entitlements';
import { FcscUploadVorabFolderComponent } from '@fcsc/fcsc-uploadtool/fcsc-upload-vorab/components/fcsc-upload-vorab-folder/fcsc-upload-vorab-folder.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { userSettingKeys } from '@shared/constants/UsertSettingKeys';
import { DataPool } from '@shared/models/filetype/Datapool';
import {
  FilteredSteuerDaten,
  SteuerDatenMap,
  SteuerDatenResponse,
} from '@shared/models/filetype/SteuerDaten';
import { SdpCreationRequest } from '@shared/models/ImportFile';
import { ModalResult } from '@shared/models/ModalResult';
import { Page } from '@shared/models/Page';
import { CheckBoxSelect } from '@shared/models/Select';
import { GeneralPageInfo, SortInfo } from '@shared/models/SortInfo';
import { Spinner } from '@shared/models/spinner';
import { SelectionType } from '@swimlane/ngx-datatable';
import { forkJoin } from 'rxjs';
import { Status } from 'src/app/core/enums/status-color.enum';
import { SteuerdatenService } from 'src/app/core/services/steuerdaten.service';
import { getStatusColor } from 'src/app/core/utils/getStatusColor/getStatusColor';

import { FcscFilterVorabComponent } from './components/fcsc-filter-vorab/fcsc-filter-vorab.component';
import { FcscSdpDetailsComponent } from './components/fcsc-sdp-details/fcsc-sdp-details.component';
import { FcscUploadvorabDeleteConfirmationComponent } from './components/fcsc-upload-vorab-delete-confirmation/fcsc-upload-vorab-delete-confirmation.component';

@Component({
  selector: 'app-fcsc-upload-vorab',
  templateUrl: './fcsc-upload-vorab.component.html',
  styleUrls: ['./fcsc-upload-vorab.component.scss'],
})
export class FcscUploadVorabComponent implements OnInit {
  page = new Page();
  sortConfig!: Array<{}>;
  paginationDataSetting!: any;
  params: GeneralPageInfo | undefined;
  paramsCheckBox: GeneralPageInfo | undefined;
  steuerDaten: SteuerDatenMap[] = [];
  response!: SteuerDatenResponse | null;
  selected: SteuerDatenMap[] = [];
  finalSelectedMap = {};
  sdpNameList = [''];
  sdpNameNotExisting: string[] = [];
  dataTableSelection = SelectionType.checkbox;
  isLoading = false;
  loadingTitle!: string;
  loadingSubtitle!: string;
  spinnerObject!: Spinner;
  fileUploadspinnerObject!: Spinner;
  selectedFilter!: FilteredSteuerDaten;
  selectedFilterCheckBox!: FilteredSteuerDaten;
  isMultiDeleteButtonDisabled: boolean = true;
  isNaN = Number.isNaN;
  form!: UntypedFormGroup;
  @ViewChild('filterComponent')
  filterComponent!: FcscFilterVorabComponent;
  @Input() filteredData: SteuerDatenMap[] = [];

  public deleteEntitlement = [entitlements.VUS.FCSC_VORAB_DATA_IMAGE_DELETE];
  public importEntitlement = [entitlements.VUS.FCSC_VORAB_DATA_IMPORT];
  public zipExportEntitlement = [entitlements.VUS.FCSC_VORAB_DATA_EXPORT];
  public tableListEntitlement = [entitlements.VUS.FCSC_VORAB_DATA];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private steuerdatenService: SteuerdatenService,
    private modalService: NgbModal,
    private notificationService: ErrorHandlerService,
    private fileImportService: FileImportService,
    private translocoService: TranslocoService,
    private appService: AppService,
    private calculationLogFilesService: CalculationLogFilesService,
    private downloadService: DownloadService,
    private userSettingService: UserSettingService
  ) {}

  ngOnInit(): void {
    this.spinnerObject = {
      loadingTitle: 'global.loading-spinner-fetch-title',
      loadingSubtitle: 'global.loading-spinner-fetch-subtitle',
      isLoading: false,
    };
    this.fileUploadspinnerObject = {
      loadingTitle: 'modules.data-management.modal-upload-title',
      loadingSubtitle: 'modules.data-management.manual-upload-spinner-subtitle',
      isLoading: false,
    };

    this.appService.spinnerSubject.subscribe((spinnerObject: Spinner) => {
      this.loadingTitle = spinnerObject.loadingTitle;
      this.loadingSubtitle = spinnerObject.loadingSubtitle;
      this.isLoading = spinnerObject.isLoading;
    });
    this.createForm();
    this.refreshDataTable(this.resultSdpNames());
  }

  onSelect(event: CheckBoxSelect): void {
    this.selected = [...event.selected];
    const selectedNameKeys = this.selected.map((item) => item.name);
    this.finalSelectedMap[this.page.pageNumber] = selectedNameKeys;
    this.getDataOnPageChange(this.page);
    this.checkDisabilityMultiDelete(this.selected);
  }

  private resultSdpNames(): string[] {
    const selectedNameKeys = this.selected
      .map((item) => item.name)
      .concat(this.sdpNameNotExisting);
    this.finalSelectedMap[this.page.pageNumber] = selectedNameKeys;

    const result: any[] = selectedNameKeys.filter(
      (item, pos) => selectedNameKeys.indexOf(item) === pos
    );
    this.sdpNameList = result.filter((el) => el !== '');
    return result;
  }

  private loadContent() {
    this.steuerDaten = (this.response && this.response?.content) || [];
    const filteredList = this.steuerDaten.filter((lhd) => lhd.alreadySelected);
    const sdpNames = this.steuerDaten.map((val) => val.name);
    this.selected = [...filteredList];
    const sdpNotExisting: string[] = [];

    this.sdpNameList.forEach((name) => {
      if (!sdpNames.includes(name)) {
        sdpNotExisting.push(name);
      }
    });
    this.sdpNameNotExisting = sdpNotExisting;
    const filteredListCopy = JSON.parse(JSON.stringify(filteredList));

    this.finalSelectedMap[this.page.pageNumber] = filteredListCopy.map(
      (val) => val.name
    );
  }

  async retrieveVorabSteuerDaten(
    dataPool: string,
    name: string[],
    params?: FilteredSteuerDaten
  ) {
    this.spinnerObject.isLoading = true;
    this.appService.spinnerSubject.next(this.spinnerObject);
    const nameCheck = name ? name : [];
    this.steuerdatenService
      .postVorabSteuerDaten(dataPool, nameCheck, params)
      .subscribe(
        (data: HttpResponse<SteuerDatenResponse>) => {
          this.spinnerObject.isLoading = false;
          this.appService.spinnerSubject.next(this.spinnerObject);
          this.response = data.body;
          this.loadContent();
        },
        (error: HttpErrorResponse) => {
          this.spinnerObject.isLoading = false;
          this.appService.spinnerSubject.next(this.spinnerObject);
          this.notificationService.handleServerErrorNotification(
            error,
            'modules.data-management.serien-daten.modal.bdp-activation-error-message'
          );
        }
      );
  }

  public truncateNote(value: string): string {
    const isExpanded = value.length > 250;
    return value.substring(0, 250) + (isExpanded ? '...' : '');
  }

  public deleteData(row: SteuerDatenMap): void {
    const modalRef = this.modalService.open(
      FcscUploadvorabDeleteConfirmationComponent,
      {
        size: 'lg',
        windowClass: 'confirmation-modal',
        backdrop: 'static',
        keyboard: false,
      }
    );
    const isSelected = this.form.get('selectedOriginalSdps')?.value;
    const filter = this.selectedFilter || {};
    const filterCheckBox = this.selectedFilterCheckBox || {};
    const selectedFilter = isSelected ? filterCheckBox : filter;

    modalRef.componentInstance.message = this.translocoService.translate(
      'modules.data-management.delete-data-packet',
      { name: row.name }
    );
    modalRef.result.then((result) => {
      if (result && result.ok === ModalResult.DELETE_OK) {
        this.steuerdatenService.deleteSteuerDaten(row.name).subscribe(
          () => {
            this.notificationService.showSuccess(
              '',
              this.translocoService.translate(
                'modules.data-management.delete-success',
                { name: row.name }
              )
            );
            this.refreshDataTable(this.resultSdpNames(), selectedFilter, true);
          },
          (error: HttpErrorResponse) => {
            this.isLoading = false;
            this.notificationService.handleServerErrorNotification(error, '');
          }
        );
      }
    });
  }

  deleteMultipleData(): void {
    const isSelected = this.form.get('selectedOriginalSdps')?.value;
    const filter = this.selectedFilter || {};
    const filterCheckBox = this.selectedFilterCheckBox || {};
    const selectedFilter = isSelected ? filterCheckBox : filter;

    if (this.sdpNameList.length) {
      this.spinnerObject.isLoading = true;
      this.appService.spinnerSubject.next(this.spinnerObject);
      this.steuerdatenService
        .deleteMutipleSteuerDaten(this.sdpNameList)
        .subscribe(
          () => {
            this.sdpNameList = [];
            this.finalSelectedMap = {};

            if (isSelected === true) {
              selectedFilter.page = '0';
            } else if (isSelected === false) {
              filter.page = '0';
            }
            this.selectedFilter = selectedFilter;

            delete selectedFilter.showOnlySelectedSdps;

            this.form?.patchValue({
              selectedOriginalSdps: false,
            });

            this.spinnerObject.isLoading = false;
            this.appService.spinnerSubject.next(this.spinnerObject);
            this.refreshDataTable(this.sdpNameList, this.selectedFilter, true);

            this.notificationService.showSuccess(
              '',
              this.translocoService.translate(
                'modules.data-management.delete-multi-success'
              )
            );
          },
          (error) => {
            this.spinnerObject.isLoading = false;
            this.appService.spinnerSubject.next(this.spinnerObject);
            this.notificationService.handleServerErrorNotification(
              error,
              'modules.admin-section.config-modal.insert-edit-api-error-message'
            );
          }
        );
    }
  }

  public checkDisability(row: SteuerDatenMap) {
    const selectedRow: SteuerDatenMap = row;
    return selectedRow.deletable === false
      ? 'option-svg-disabled'
      : 'option-svg';
  }

  public isActionDisabled(row: SteuerDatenMap) {
    const selectedRow: SteuerDatenMap = row;
    return selectedRow.deletable === false;
  }

  public exportSdpFiles(sdp: SteuerDatenMap): void {
    const selectedRowName = sdp.name;

    this.steuerdatenService.getExportFilesAsZipDownload(sdp.id).subscribe(
      (response) => {
        this.downloadService.createAndDownloadFile(
          response,
          `${selectedRowName}`,
          '.zip'
        );
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(error, '');
      }
    );
  }

  exportSmokeTestFaildFile(file: SteuerDatenMap): void {
    this.steuerdatenService
      .getPresignedUrlForSmokeTestFile(file.name)
      .subscribe(
        (response: any) => {
          const url = response?.presignedUrl.toString();
          this.calculationLogFilesService
            .getExportFileFromPresignedUrl(url)
            .subscribe(
              (res: Blob) => {
                this.downloadService.createAndDownloadFile(
                  res,
                  `${file.name}_response`,
                  '.log'
                );
              },
              (error) => {
                this.notificationService.handleServerErrorNotification(
                  error,
                  'modules.admin-section.config-modal.insert-edit-api-error-message'
                );
              }
            );
        },
        (error) => {
          this.notificationService.handleServerErrorNotification(
            error,
            'modules.admin-section.config-modal.insert-edit-api-error-message'
          );
        }
      );
  }

  public async getFileObservables(result: any, response: any) {
    const fileObservables: any = [];
    for (const fileData of result.filesData) {
      const fileDataText = await this.fileImportService.getFileData(fileData);
      fileObservables.push(
        this.fileImportService.uploadFile(response.name, fileData, fileDataText)
      );
    }
    return fileObservables;
  }

  public uploadData(): void {
    const modalRef = this.modalService.open(FcscUploadVorabFolderComponent, {
      size: 'lg',
      windowClass: 'bdp-edit-modal',
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.result.then((result) => {
      // add the spinner and notifications after the upload wizard is closed
      if (result === ModalResult.IMPORT_CANCEL) {
        return;
      }
      this.fileUploadspinnerObject.isLoading = true;
      this.appService.spinnerSubject.next(this.fileUploadspinnerObject);
      const payload: SdpCreationRequest = {
        name: result.form.controls.name.value,
        series: result.form.controls.selectedSeries.value,
        dataPool: DataPool.VORAB,
        note: result.form.controls.note.value,
        deltaUploadFileIds: result.deltaUploadFileIds,
      };
      this.fileImportService.createSdp(payload).subscribe(
        async (response: any) => {
          if (response.name) {
            const fileObservables: any = await this.getFileObservables(
              result,
              response
            );

            forkJoin(fileObservables).subscribe((resp: any) => {
              if (resp) {
                this.refreshDataTable(this.resultSdpNames());
                this.fileImportService
                  .createBdp(response.name)
                  .subscribe(() => {
                    this.notificationService.showSuccess(
                      '',
                      this.translocoService.translate(
                        'modules.data-management.upload-success'
                      )
                    );
                    this.fileUploadspinnerObject.isLoading = false;
                    this.appService.spinnerSubject.next(
                      this.fileUploadspinnerObject
                    );
                  });
              }
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.notificationService.handleServerErrorNotification(
            error,
            'modules.data-management.upload-failed'
          );
          this.fileUploadspinnerObject.isLoading = false;
          this.appService.spinnerSubject.next(this.fileUploadspinnerObject);
        },
        () => {}
      );
    });
  }

  sdPaketsDetails(row: SteuerDatenMap, value: string): void {
    const modalRef = this.modalService.open(FcscSdpDetailsComponent, {
      size: 'lg',
      windowClass: 'details-modal',
      backdrop: 'static',
      keyboard: false,
    });
    const selectedRow: SteuerDatenMap = row;
    modalRef.componentInstance.title = this.translocoService.translate(
      'modules.data-management.sdp-details-title',
      { name: selectedRow.name }
    );
    modalRef.componentInstance.showCrossClose = true;
    modalRef.componentInstance.sdpName = value;
  }

  public getColor(value: string): string {
    const enumValue = Status[value as keyof typeof Status];
    return getStatusColor(enumValue);
  }

  public isStatusSpinnerLoaded(value: string): boolean {
    const enumValue = Status[value as keyof typeof Status];
    return enumValue === Status.INITIAL;
  }

  isSmokeTestFailed(row: SteuerDatenMap): boolean {
    return (
      row?.status ===
      this.appService.getValueConstant(Status, Status.SMOKE_TEST_FAILED)
    );
  }

  public getValue(value: string): string {
    let translatedText = '';
    this.translocoService
      .selectTranslate(Status[value as keyof typeof Status])
      .subscribe((resp) => {
        translatedText = resp;
      });
    return translatedText;
  }

  public getBDPValue(value: string): string {
    let translatedText = '';
    this.translocoService
      .selectTranslate(StatusBdp[value as keyof typeof StatusBdp])
      .subscribe((resp) => {
        translatedText = resp;
      });
    return translatedText;
  }

  /*  checkbox functionality */

  private createForm() {
    this.form = this.formBuilder.group({
      selectedOriginalSdps: new UntypedFormControl(false),
    });
  }

  handleSdpsCheckChange() {
    this.refreshDataTable(this.sdpNameList, this.selectedFilter, true);
  }

  resetFilterFieldsOnCheckBoxChange() {
    this.filterComponent.resetFilteredRowsForCheckBox();
  }

  /*  pagination funcutionality */

  private async refreshDataTable(
    sdpList: string[],
    filter?,
    pageNotRefresh?: boolean
  ) {
    await this.configPageInfoAfterInit();
    const isSelected = this.form.get('selectedOriginalSdps')?.value;
    if (isSelected) {
      await this.configPageInfoAfterInit();
      this.resetFilterFieldsOnCheckBoxChange();
      await this.retrieveVorabSteuerDaten(
        DataPool.VORAB,
        sdpList,
        this.paramsCheckBox
      );
    } else if (!isSelected) {
      this.resetFilterFieldsOnCheckBoxChange();
      await this.retrieveVorabSteuerDaten(DataPool.VORAB, sdpList, this.params);
    }
  }

  private async getDataOnPageChange(page) {
    const isSelected = this.form.get('selectedOriginalSdps')?.value;
    const filter = this.selectedFilter || {};
    const filterCheckBox = this.selectedFilterCheckBox || {};
    const selectedFilter = isSelected ? filterCheckBox : filter;
    const sortConf = [this.page.orderBy, this.page.orderDir].toString();
    selectedFilter.size = this.page.size.toString();
    selectedFilter.sort = sortConf.toString();
    if (!isSelected) {
      delete selectedFilter.showOnlySelectedSdps;
    } else {
      selectedFilter.showOnlySelectedSdps = true;
    }

    if (isSelected === true) {
      if (page) {
        selectedFilter.page = (+this.page.pageNumber - 1).toString();
      } else {
        selectedFilter.page = '0';
      }
      this.selectedFilterCheckBox = selectedFilter;
    } else if (isSelected === false) {
      if (page) {
        filter.page = (+this.page.pageNumber - 1).toString();
      } else {
        filter.page = '0';
      }
      this.selectedFilter = selectedFilter;
    }
    this.appService.resetDatatableScroll();

    await this.retrieveVorabSteuerDaten(
      DataPool.VORAB,
      this.resultSdpNames(),
      selectedFilter
    );

    const value = {
      size: filter.size,
      sort: filter.sort,
    };
    this.saveUserSetting(value);
  }

  public async setFilter(event: FilteredSteuerDaten) {
    const isSelected = this.form.get('selectedOriginalSdps')?.value;
    const filter = this.selectedFilter || {};
    const filterCheckBox = this.selectedFilterCheckBox || {};
    const selectedFilter = isSelected ? filterCheckBox : filter;
    await this.configPageFilterInfoAfterInit(selectedFilter);
    this.sortConfig = [{ prop: this.page.orderBy, dir: this.page.orderDir }];
    selectedFilter.baureihe = event && event.baureihe ? event.baureihe : '';
    selectedFilter.sdp = event && event.sdp ? event.sdp : '';
    selectedFilter.username = event && event.username ? event.username : '';
    selectedFilter.uploaded_at =
      event && event.uploaded_at ? event.uploaded_at : '';
    selectedFilter.status = event && event.status ? event.status : '';

    this.checkFilteredSelection(
      isSelected,
      filterCheckBox,
      selectedFilter,
      filter
    );

    await this.retrieveVorabSteuerDaten(
      DataPool.VORAB,
      this.resultSdpNames(),
      selectedFilter
    );
    const value = {
      size: filter.size,
      sort: filter.sort,
    };
    this.saveUserSetting(value);
  }

  private checkFilteredSelection(
    isSelected,
    filterCheckBox,
    selectedFilter,
    filter
  ) {
    if (isSelected) {
      this.selectedFilter = { ...this.selectedFilter, ...filterCheckBox };
      selectedFilter.showOnlySelectedSdps = true;
      this.selectedFilterCheckBox = {
        ...this.selectedFilterCheckBox,
        ...filterCheckBox,
      };
    } else if (!isSelected) {
      this.selectedFilter = { ...this.selectedFilter, ...filter };
    }
  }

  public setPageSize(size) {
    this.page.pageNumber = 1;
    this.page.size = size;
    this.getDataOnPageChange(null);
  }

  public setPage(pageInfo) {
    this.page.pageNumber = pageInfo.page;
    this.getDataOnPageChange(this.page);
  }

  public sortCallback(sortInfo: SortInfo) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.getDataOnPageChange(this.page);
  }

  // Saves user data on user interaction on sort, filter and page change.
  private saveUserSetting(data) {
    this.userSettingService.saveUserSetting(
      userSettingKeys.vorab.vorabTable,
      data
    );
  }

  // Gets the user data or loads default ones.
  private async configPageInfoAfterInit() {
    this.spinnerObject.isLoading = true;
    this.appService.spinnerSubject.next(this.spinnerObject);
    this.paginationDataSetting =
      (await this.userSettingService.fetchUserSetting(
        userSettingKeys.vorab.vorabTable
      )) || {};

    const { sort, size } = this.paginationDataSetting;
    // Sort
    const sortData = sort || 'createdAt,desc';
    const sortArray = sortData.split(',');
    const orderBy = sortArray[0];
    const orderDir = sortArray[1];
    this.sortConfig = [{ prop: orderBy, dir: orderDir }];

    // Order
    this.page.orderDir = orderDir;
    this.page.orderBy = orderBy;

    // Page
    this.page.pageNumber = 1;

    // Size
    this.page.size = this.page.size = size || 30;

    const sortConfArr = [this.page.orderBy, this.page.orderDir].toString();

    this.params = {
      page: '0',
      size: size || '30',
      sort: sortConfArr,
    };

    this.paramsCheckBox = {
      page: '0',
      size: size || '30',
      sort: sortConfArr,
      showOnlySelectedSdps: true,
    };
  }

  private async configPageFilterInfoAfterInit(filter: any) {
    await this.configPageInfoAfterInit();
    const { sort, size } = this.paginationDataSetting;
    const sortData = sort || 'createdAt,desc';
    const sortArray = sortData.split(',');
    const orderBy = sortArray[0];
    const orderDir = sortArray[1];
    const sortConfigFilter = [orderBy, orderDir];
    filter.page = '0';
    filter.size = size || '30';
    filter.sort = sortConfigFilter.toString();
  }

    // Disable Multi Delete button when deletable property is false for any record in the response
    private checkDisabilityMultiDelete(selected): boolean {
      selected = this.selected?.some((val) =>
        val.deletable === false
          ? (this.isMultiDeleteButtonDisabled = true)
          : (this.isMultiDeleteButtonDisabled = false),
      );
      return selected;
    }
}
